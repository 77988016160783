/* ***** ----------------------------------------------- ***** **
** ***** Section Title JS
** ***** ----------------------------------------------- ***** */

/* global Main, TweenMax, Power2 */
(function($){
	'use strict';

	Main.modules.sectionTitle = function() {

		var $body = $('.js-body'),
			$sectionTitle = $('.js-section_title'),

			toggleDropdown = function() {

				// Toggle the display of the section title dropdown menu

				var $body = $('.js-body'),
					enableContentRevealAnimations = $body.hasClass('s-content_reveal_animations-is_enabled');

				$body.toggleClass('s-dropdown-is_open');

				// If content reveal animations are enabled, animate dropdown items
				if ($body.hasClass('s-dropdown-is_open') && enableContentRevealAnimations) {
					openAnimation();
				}

			},

			openAnimation = function() {

				// Stagger animate the menu items

				var $dropdownItem = $sectionTitle.find('.js-section_title-dropdown_item');

				TweenMax.staggerFromTo($dropdownItem, 0.5, { opacity: 0, y: '-20px' }, { opacity: 1, y: '0px', delay: 0.2, ease: Power2.easeOut }, 0.05);

			},

			closeDropdownOnClick = function(e) {

				// If the dropdown menu is opened and the user clicks outside of it, close
				
				if ($body.hasClass('s-dropdown-is_open') && !$(e.target).closest('.c-section_title').length) {
					closeDropdown();
				}

			},

			closeDropdown = function() {
				$body.removeClass('s-dropdown-is_open');
			};

		return {
			init: function() {
				var $document = $(document),
					$toggle = $('.js-toggle_submenu');

				$toggle.on('click', toggleDropdown);
				$document.on('click', closeDropdownOnClick);
			},
			closeDropdown: closeDropdown
		};
		
	};

})(jQuery);