/* ***** ----------------------------------------------- ***** **
** ***** Photos JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.photos = function() {

		var fancybox = function() {

				// Enable fancybox and update pagination arrows

				$('[data-fancybox]').fancybox({
					loop: true,
					buttons: [
						'close'
					],
					btnTpl: {
						arrowLeft:
							'<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;">' +
							'<svg viewBox="0 0 50 50" aria-hidden="true">' +
							'<use xlink:href="' + Main.themeURL + '/assets/dist/svgs/sprite.svg#arrow-left"></use>' +
							'</svg>' +
							'</a>',

						arrowRight:
							'<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;">' +
							'<svg viewBox="0 0 50 50" aria-hidden="true">' +
							'<use xlink:href="' + Main.themeURL + '/assets/dist/svgs/sprite.svg#arrow-right"></use>' +
							'</svg>' +
							'</a>'
					}
				});

			};

		return {
			init: function() {
				fancybox();
			}
		};
		
	};

})(jQuery);