/* ***** ----------------------------------------------- ***** **
** ***** Hero JS
** ***** ----------------------------------------------- ***** */

/* global Main, TweenMax, Power2 */
(function($){
	'use strict';

	Main.modules.hero = function() {

		var $body = $('.js-body'),

			slickInit = function() {

				// Init all elements of slick slider

				var $homeHeroSlides = $('.js-home_hero-slides'),
					enableContentRevealAnimations = $body.hasClass('s-content_reveal_animations-is_enabled');

				// If content reveal animations are enabled, animate first slide background and image on load
				if (enableContentRevealAnimations) {
					$homeHeroSlides.on('init', function() {

						var $currentSlide = $('.slick-slide[data-slick-index="0"]'),
							$currentSlideAnimateItem = $currentSlide.find('.js-home_hero-animate_from_bottom'),
							$currentSlideBackground = $currentSlide.find('.js-home_hero-background'),
							$heroSliderElements = $('.js-home_hero-slider_elements, .slick-dots');

						// Reinit lazyload on slides to ensure the images in the cloned elements are loaded
						var $slideBackground = $homeHeroSlides.find('.js-home_hero-background');
						$slideBackground.addClass('lazyload');

						// Animate first slide's background
						TweenMax.fromTo($currentSlideBackground, 1.2, { 
							scale: 1.1,
						}, {
							scale: 1,
							ease: Power2.easeOut
						});

						// Animate first slide's content
						TweenMax.staggerFromTo($currentSlideAnimateItem, 1, {
							opacity: 0,
							y: '30px'
						}, {
							opacity: 1,
							y: '0px',
							delay: 0.5,
							ease: Power2.easeOut,
						}, 0.15);

						// Animate hero slider elements
						TweenMax.fromTo($heroSliderElements, 1, {
							opacity: 0
						}, {
							opacity: 1,
							delay: 1.2,
							ease: Power2.easeOut,
							clearProps: 'all',
							onComplete: function() {
								$heroSliderElements.removeClass('u-animation-opacity--0');
							}
						});

					});
				}

				// Initialize slick carousel
				$homeHeroSlides.slick({
					dots: true,
					prevArrow: $('.js-home_hero-arrow--prev'),
					nextArrow: $('.js-home_hero-arrow--next')
				});

				// If content reveal animations are enabled, animate slide content on slide change
				if (enableContentRevealAnimations) {
					$homeHeroSlides.on('beforeChange', function(event, slick, currentSlide, nextSlide) {

						var $currentSlide = $('.slick-slide[data-slick-index="' + nextSlide + '"]'),
							$currentSlideAnimateItem = $currentSlide.find('.js-home_hero-animate_from_bottom'),
							$slide = $('.slick-slide'),
							$slideAnimateItem = $slide.find('.js-home_hero-animate_from_bottom');

						TweenMax.staggerFromTo($currentSlideAnimateItem, 0.75, { 
							opacity: 0,
							y: '30px'
						}, {
							opacity: 1,
							y: '0px',
							delay: 0.5,
							ease: Power2.easeOut
						}, 0.15);

						TweenMax.to($slideAnimateItem, 0.5, { opacity: 0 });

					});
				}

			};

		return {
			init: function() {
				slickInit();
			}
		};
		
	};

})(jQuery);