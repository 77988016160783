/* ***** ----------------------------------------------- ***** **
** ***** Music JS
** ***** ----------------------------------------------- ***** */

/* global Main, Howl, fhAjax */
(function($){
	'use strict';

	Main.modules.music = function() {

		var soundCloudClientId = '3563736e21550fcd53b67b8dab55a4db',
			track,
			trackProgressAnimation,

			loadTracklistGroups = function() {

				// For all tracklist groups, based on their platform, query their tracks and display

				var $tracklistGroup = $('.js-music_preview-tracklist_group');

				$tracklistGroup.each(function() {

					var $this = $(this),
						spotifyAlbumId = $this.data('spotify_album_id'),
						soundCloudPlaylistUrl = $this.data('soundcloud_playlist_url');

					if (spotifyAlbumId) {
						loadSpotifyAlbum(spotifyAlbumId, $this);
					} else if (soundCloudPlaylistUrl) {
						loadSoundCloudPlaylist(soundCloudPlaylistUrl, $this);
					}

				});

			},

			loadSpotifyAlbum = function(spotifyAlbumId, $element) {

				// Get the album's tracks from a native WP AJAX call that gets data from the Spotify API
				// Implemented server and client side for seamless authentication with API

				var postData = {
					action: 'fh_get_spotify_album_tracks',
					nonce: fhAjax.fhAjaxNonce,
					spotifyAlbumId: spotifyAlbumId
				};

				$.ajax({
					type: 'POST',
					url: fhAjax.fhAjaxUrl,
					data: postData,
					dataType: 'json',
					cache: false,
					success: function(tracks) {
						var tracksMarkup = renderSpotifyTracks(tracks);
						$element.html(tracksMarkup);
					},
					error: function() {
						console.log('There was an error with loading the album.'); // eslint-disable-line no-console
					}
				});

			},

			loadSoundCloudPlaylist = function(soundCloudPlaylistUrl, $element) {

				// Get the playlist's tracks from the SoundCloud API

				var requestUrl = '//api.soundcloud.com/resolve?url=' + soundCloudPlaylistUrl + '&client_id=' + soundCloudClientId;

				$.ajax({
					url: requestUrl,
					success: function(playlist) {
						var tracksMarkup = renderSoundCloudTracks(playlist.tracks);
						$element.html(tracksMarkup);
					},
					error: function(jqXHR, textStatus, errorThrown) {
						console.log(errorThrown); // eslint-disable-line no-console
					}
				});


			},

			renderSpotifyTracks = function(tracks) {

				var tracksMarkup = '';

				$.each(tracks, function(index, track) {

					var trackNumber = track.track_number,
						trackTitle = track.name,
						trackPreviewUrl = track.preview_url,
						trackMarkup = renderTrack(trackNumber, trackTitle, trackPreviewUrl, 'spotify');

					tracksMarkup += trackMarkup;

				});

				return tracksMarkup;

			},

			renderSoundCloudTracks = function(tracks) {

				var tracksMarkup = '';

				$.each(tracks, function(index, track) {

					var trackNumber = index + 1,
						trackTitle = track.title,
						trackStreamUrl = track.stream_url,
						trackMarkup = renderTrack(trackNumber, trackTitle, trackStreamUrl, 'soundcloud');

					tracksMarkup += trackMarkup;

				});

				return tracksMarkup;
			},

			renderTrack = function(trackNumber, trackTitle, trackUrl, platform) {

				var trackData,
					trackMarkup = '';

				if (platform == 'spotify') {
					trackData = ' data-spotify_preview_url="' + trackUrl + '"';
				} else if (platform == 'soundcloud') {
					trackData = ' data-soundcloud_stream_url="' + trackUrl + '"';
				}

				trackMarkup += '<div class="o-track' + ( trackUrl ? ' o-track--has_playback js-play_pause_track' : '' ) + '"' + trackData + '>';
				trackMarkup += '<div class="o-track-number_wrapper js-track-number_wrapper">';
				trackMarkup += '<div class="o-track-number">' + trackNumber + '</div>';

				if (trackData) {
					trackMarkup += '<button class="o-track-play_pause_button" type="button" aria-label="Play/Pause Track">';
					trackMarkup += '<svg class="o-track-button_svg o-track-button_svg--play" viewBox="0 0 50 50" aria-hidden="true">';
					trackMarkup += '<use xlink:href="' + Main.themeURL + '/assets/dist/svgs/sprite.svg#icon-play"></use>';
					trackMarkup += '</svg>';
					trackMarkup += '<svg class="o-track-button_svg o-track-button_svg--pause" viewBox="0 0 50 50" aria-hidden="true">';
					trackMarkup += '<use xlink:href="' + Main.themeURL + '/assets/dist/svgs/sprite.svg#icon-pause"></use>';
					trackMarkup += '</svg>';
					trackMarkup += '<svg class="o-track-progress_svg js-track-progress_svg" viewBox="0 0 22.5 22.5">';
					trackMarkup += '<circle cx="11.25" cy="11.25" r="10.25">';
					trackMarkup += '</svg>';
					trackMarkup += '</button>';
				}

				trackMarkup += '</div>';

				if (trackTitle) trackMarkup += '<div class="o-track-title">' + trackTitle + '</div>';

				trackMarkup += '</div>';

				return trackMarkup;

			},

			playPauseTrack = function() {

				// Play or pause the track based on current track state

				var $this = $(this),
					thisHowlData = $this.data('howl');

				// If a track is loaded and currently playing, pause
				// If a track is loaded and this track is playing, pause and do not load a new track
				if (track != null) {
					if (track.playing()) track.pause();
					if ($this.hasClass('s-is_playing')) return;
				}

				// If this track has previously been loaded, play
				if (thisHowlData) {

					track = thisHowlData;
					track.play();
					track.fade(0, 1, 500);

				} else {

					var soundCloudUrl = $this.data('soundcloud_url'),
						soundCloudStreamUrl = $this.data('soundcloud_stream_url'),
						spotifyPreviewUrl = $this.data('spotify_preview_url'),
						spotifyTrackId = $this.data('spotify_track_id'),
						trackUrl = $this.data('track_url');

					// Display loading dots and state as soon as the user clicks on a playable track
					addLoadingDots($this);
					addLoadingState($this);

					// Play sound based on source - SoundCloud, Spotify or native
					if (soundCloudUrl) {
						playSoundCloudTrack(soundCloudUrl, $this);
					} else if (soundCloudStreamUrl) {
						var updatedSoundCloudStreamUrl = updateSoundCloudStreamUrl(soundCloudStreamUrl);
						playTrack(updatedSoundCloudStreamUrl, $this);
					} else if (spotifyPreviewUrl) {
						playTrack(spotifyPreviewUrl, $this);
					} else if (spotifyTrackId) {
						playSpotifyTrack(spotifyTrackId, $this);
					} else if (trackUrl) {
						playTrack(trackUrl, $this);
					}

				}	

			},

			playSoundCloudTrack = function(soundCloudUrl, $element) {

				// Get the track's preview URL from the SoundCloud API

				var requestUrl = '//api.soundcloud.com/resolve?url=' + soundCloudUrl + '&client_id=' + soundCloudClientId;

				$.ajax({
					url: requestUrl,
					success: function(data) {
						var soundCloudStreamUrl = updateSoundCloudStreamUrl(data.stream_url);
						playTrack(soundCloudStreamUrl, $element);
					},
					error: function(jqXHR, textStatus, errorThrown) {
						removeLoadingDots($element);
						removeLoadingState($element);
						console.log(errorThrown); // eslint-disable-line no-console
					}
				});

			},

			playSpotifyTrack = function(spotifyTrackId, $element) {

				// Get the track's preview URL from a native WP AJAX call that gets data from the Spotify API
				// Implemented server and client side for seamless authentication with API

				var postData = {
					action: 'fh_get_spotify_track_preview_url',
					nonce: fhAjax.fhAjaxNonce,
					spotifyTrackId: spotifyTrackId
				};

				$.ajax({
					type: 'POST',
					url: fhAjax.fhAjaxUrl,
					data: postData,
					dataType: 'json',
					cache: false,
					success: function(trackPreviewUrl) {
						if (trackPreviewUrl) {
							playTrack(trackPreviewUrl, $element);
						} else {
							removeLoadingDots($element);
							removeLoadingState($element);
							console.log('There was an error. The track was unable to load or there is no preview track available.'); // eslint-disable-line no-console
						}
					},
					error: function() {
						removeLoadingDots($element);
						removeLoadingState($element);
					}
				});

			},

			playTrack = function(trackUrl, $element) {

				// Create a new sound based on track URL and play

				if (trackUrl == null) return;

				track = new Howl({
					src: trackUrl,
					format: ['mp3'],
					onload: function() {
						removeLoadingState($element);
						setTimeout(function() {
							removeLoadingDots($element);
						}, 250);
					},
					onplay: function() {
						$element.addClass('s-is_playing');
						updateTrackProgress($element);
					},
					onpause: function() {
						$element.removeClass('s-is_playing');
						updateTrackProgress($element);
					},
					onstop: function() {
						$element.removeClass('s-is_playing');
						updateTrackProgress($element);
					},
					onend: function() {
						$element.removeClass('s-is_playing');
						updateTrackProgress($element);

						// If next track exists, play after this track is completed
						if ($element.next('.js-play_pause_track')) $element.next().click();
					}
				});

				// Store track object as a data element in the track element for seamless access
				$element.data('howl', track);

				// Play track with fade in effect
				track.play();
				track.fade(0, 1, 500);

			},

			updateTrackProgress = function($element) {

				// Update the progress of the current track that is playing

				var $thisTrackProgress = $element.find('.js-track-progress_svg');

				if (track.playing()) {
					trackProgressAnimation = setInterval(function() {
						var progressRange = 64,
							trackSeek = track.seek(),
							trackDuration = track.duration(),
							trackProgressPercentage = trackSeek / trackDuration,
							currentTrackProgressDashOffset = progressRange - (trackProgressPercentage * progressRange);
						
						$thisTrackProgress.css('stroke-dashoffset', currentTrackProgressDashOffset);
					}, 10);
				} else {
					clearInterval(trackProgressAnimation);
				}

			},

			addLoadingDots = function($element) {

				// Display loading dots to element

				var $thisTrackNumberWrapper = $element.find('.js-track-number_wrapper'),
					loadingDotsMarkup = renderLoadingDots();

				$thisTrackNumberWrapper.append(loadingDotsMarkup);

			},

			addLoadingState = function($element) {

				// Display loading state class to element and parent

				var $musicPreviewTracks = $element.parent('.js-music_preview-tracks');

				$element.addClass('s-is_loading');
				$musicPreviewTracks.addClass('s-is_loading');

			},

			renderLoadingDots = function() {

				// Render the loading dots animation that is displayed when the user clicks on a playable track

				var loadingDotsMarkup = '';

				loadingDotsMarkup += '<div class="o-track-loading s-is_active js-track-loading">';
				loadingDotsMarkup += '<div class="o-loading_dots o-loading_dots--small">';
				loadingDotsMarkup += '<div class="o-loading_dots-dot o-loading_dots-dot--1"></div>';
				loadingDotsMarkup += '<div class="o-loading_dots-dot o-loading_dots-dot--2"></div>';
				loadingDotsMarkup += '<div class="o-loading_dots-dot o-loading_dots-dot--3"></div>';
				loadingDotsMarkup += '</div>';
				loadingDotsMarkup += '</div>';

				return loadingDotsMarkup;

			},

			removeLoadingDots = function($element) {

				// Animate out the opacity of the loading dots and remove element

				var $thisLoadingDots = $element.find('.js-track-loading');

				$thisLoadingDots.removeClass('s-is_active');

				// Remove loading dots after animation is complete
				setTimeout(function() {
					$thisLoadingDots.remove();
				}, 250);

			},

			removeLoadingState = function($element) {

				// Remove loading state on elements

				var $musicPreviewTracks = $element.parent('.js-music_preview-tracks');

				$element.removeClass('s-is_loading');
				$musicPreviewTracks.removeClass('s-is_loading');

			},

			updateSoundCloudStreamUrl = function(url) {

				// Get appropriate stream url depending on whether the playlist is private or public.
				// If the track includes a *secret_token* add a '&' to the url, else add a '?'.
				// Finally, append the consumer key and you'll have a working stream url.

				if (url.indexOf('secret_token') == -1) {
					url += '?';
				} else {
					url += '&';
				}
				
				url += 'client_id=' + soundCloudClientId;

				return url;

			};

		return {
			init: function() {
				var $musicPreviewTracks = $('.js-music_preview-tracks');

				$musicPreviewTracks.on('click', '.js-play_pause_track', playPauseTrack);

				loadTracklistGroups();
			}
		};
		
	};

})(jQuery);