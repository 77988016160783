/* ***** ----------------------------------------------- ***** **
** ***** Social Share JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.socialShare = function() {

		var $body = $('.js-body'),
			popUpHeight = 550,
			popUpWidth = 600,
			popUpLeftPosition = (screen.width/2) - (popUpWidth/2),
			popUpTopPosition = (screen.height/2) - (popUpHeight/2),
			popUpSettings = 'top=' + popUpTopPosition + ', left=' + popUpLeftPosition + ', width=' + popUpWidth + ', height=' + popUpHeight + ', resizable=no, scrollbars=no',

			facebook = function() {

				// Open Facebook share window

				var shareData = $(this).data('share'),
					url = shareData.url;

				window.open('http://www.facebook.com/sharer/sharer.php?' + jQuery.param({
					'u': url
				}), 'Facebook', popUpSettings);

			},

			twitter =  function() {

				// Open Twitter tweet window

				var shareData = $(this).data('share'),
					url = shareData.url,
					text = shareData.text;

				window.open('https://twitter.com/intent/tweet?' + jQuery.param({
					'source': 'webclient',
					'text': text === null ? '' : text,
					'url': url
				}), 'Twitter', popUpSettings);

			},

			email = function() {

				// Open email client with and set email fields

				var shareData = $(this).data('share'),
					url = shareData.url,
					title = shareData.title,
					subjectPrefix = shareData.subjectPrefix,
					subject = subjectPrefix + ': ' + title,
					body = 'Check it out here: ' + url,
					emailUrl = 'mailto:?body=' + body + '&subject=' + subject;

				window.open(emailUrl, '_self');

			},

			tumblr = function() {

				// Open Tumblr share window

				var shareData = $(this).data('share'),
					url = shareData.url,
					title = shareData.title,
					caption = shareData.caption;

				window.open('https://www.tumblr.com/widgets/share/tool?' + jQuery.param({
					'canonicalUrl' : url,
					'title': title === null ? '' : title,
					'caption': caption === null ? '' : caption,
				}), 'Tumblr', popUpSettings);

			},

			googlePlus = function() {

				// Open Google+ share window

				var shareData = $(this).data('share'),
					url = shareData.url,
					text = shareData.text;

				window.open('https://plus.google.com/share?' + jQuery.param({
					'url': url,
					'text': text === null ? '' : text,
				}), 'Google Plus', popUpSettings);

			},

			pinterest = function() {

				// Open Pinterest pin window

				var shareData = $(this).data('share'),
					url = shareData.url;

				window.open('http://pinterest.com/pin/create/link/?' + jQuery.param({
					'url': url,
				}), 'Pinterest', popUpSettings);

			};

		return {
			init: function() {
				if ($body.hasClass('single')) {
					var $facebook = $('.js-social_share--facebook'),
						$twitter = $('.js-social_share--twitter'),
						$email = $('.js-social_share--email'),
						$tumblr = $('.js-social_share--tumblr'),
						$googlePlus = $('.js-social_share--google_plus'),
						$pinterest = $('.js-social_share--pinterest');
			
					$facebook.on('click', facebook);
					$twitter.on('click', twitter);
					$email.on('click', email);
					$tumblr.on('click', tumblr);
					$googlePlus.on('click', googlePlus);
					$pinterest.on('click', pinterest);
				}
			}
		};
		
	};

})(jQuery);