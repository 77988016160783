/* ***** ----------------------------------------------- ***** **
** ***** Scroll Down JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.scrollDown = function() {

		var scrollDown = function() {

				// Scroll down to the main content container

				var $header = $('.js-header'),
					$this = $(this),
					$parent = $this.parent(),
					mainContentPosition = $parent.offset().top + $parent.outerHeight() - $header.height();

				$('html, body').animate({
					scrollTop: mainContentPosition
				}, 500);

			};

		return {
			init: function() {
				var $scrollDown = $('.js-scroll_down');

				$scrollDown.on('click', scrollDown);
			}
		};
		
	};

})(jQuery);