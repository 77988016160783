/* ***** ----------------------------------------------- ***** **
** ***** Subscribe Form JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.subscribeForm = function() {

		var submitForm = function(e) {

				// Upon form submission, check which email platform is enabled and submit
			
				e.preventDefault();

				var $this = $(this);

				if ($this.data('mailchimp') != null) {

					var mailchimpActionUrl = $this.data('mailchimp'),
						mailchimpActionUrlJson = mailchimpActionUrl + '&c=?'; // Update URL for json callback

					mailchimpSubmit($this, mailchimpActionUrlJson);

				} else if ($this.data('fanbridge') != null) {

					var fanbridgeFormId = $this.data('fanbridge'),
						actionUrl = '//www.fanbridge.com/signup/1.5/submit?response=json&userid=' + fanbridgeFormId;

					fanbridgeSubmit($this, actionUrl);

				}

			},

			mailchimpSubmit = function($form, url) {

				// Submit to MailChimp

				$.ajax({
					type: 'GET',
					url: url,
					data: $form.serialize(),
					dataType: 'json',
					success: function(data) {

						var result = data.result,
							message = data.msg;

						displayMessage($form, message, result);
						
					}
				});

			},

			fanbridgeSubmit = function($form, url) {

				// Submit to FanBridge

				$.ajax({
					type: 'POST',
					url: url,
					data: $form.serialize(),
					dataType: 'json',
					success: function(data) {

						var status = data.status;

						if (status == 'ok') {

							var successMessage = 'Thank you for subscribing!';
							displayMessage($form, successMessage, 'success');

						} else if (status == 'error') {

							var errorMessage = 'There was an error with your submission. Please try again in a few moments.';
							displayMessage($form, errorMessage, 'error');

						}
						
					}
				});

			},

			displayMessage = function($form, message, type) {

				// Display message upon submission

				var $subscribeFormEmail = $form.find('.js-subscribe_form-email'),
					$subscribeFormMessage = $form.find('.js-subscribe_form-message'),
					modifier = 'o-subscribe_form-message' + (type == 'success' ? '--success' : '--error'),
					displayDelay = 0;

				// If a message is already active, animate out before animating back in
				if ($subscribeFormMessage.hasClass('s-is_active')) {
					hideMessage($form);
					displayDelay = 100;
				}

				setTimeout(function() {

					// Animate message in and add text color based on message type
					$subscribeFormMessage.html(message);
					$subscribeFormMessage.addClass('s-is_active');
					$subscribeFormMessage.addClass(modifier);

					if (type == 'success') $subscribeFormEmail.val('');

				}, displayDelay);

			},

			hideMessage = function($form) {

				// Hide message container, set to blank and remove all modifier styles

				var $subscribeFormMessage = $form.find('.js-subscribe_form-message');

				$subscribeFormMessage.removeClass('s-is_active');

				setTimeout(function() {
					$subscribeFormMessage.html('');
					$subscribeFormMessage.removeClass('o-subscribe_form-message--success');
					$subscribeFormMessage.removeClass('o-subscribe_form-message--error');
				}, 100);

			};

		return {
			init: function() {
				var $subscribeForm = $('.js-subscribe_form');

				$subscribeForm.submit(submitForm);
			}
		};
		
	};

})(jQuery);
