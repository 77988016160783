/* ***** ----------------------------------------------- ***** **
** ***** Content Animations JS
** ***** ----------------------------------------------- ***** */

/* global Main, TweenMax, Power2 */
(function($){
	'use strict';

	Main.modules.contentAnimations = function() {

		var $body = $('.js-body'),

			headerSlideIn = function($element) {

				// If a hero is active, slide header in from the top

				if (!$body.hasClass('s-hero-is_active') ||
					$body.hasClass('s-header_background-is_always_active') ||
					!$element.length) return;

				TweenMax.fromTo($element, 1, { 
					y: '-100%',
					opacity: 0
				}, {
					y: '0%',
					opacity: 1,
					delay: 0.2,
					ease: Power2.easeOut
				});

			},

			heroAnimateIn = function($element) {

				// If hero is active, run hero animation
				
				if (!$element.length) return;

				var $background = $element.find('.js-hero-background'),
					$animateItem = $element.find('.js-hero-fade_from_bottom'),
					$scrollDown = $element.find('.js-scroll_down');

				// Animate background
				TweenMax.fromTo($background, 1.2, { 
					scale: 1.1
				}, {
					scale: 1,
					ease: Power2.easeOut
				});

				// Animate content
				TweenMax.staggerFromTo($animateItem, 1, {
					opacity: 0,
					y: '30px'
				}, {
					opacity: 1,
					y: '0px',
					delay: 0.6,
					ease: Power2.easeOut
				}, 0.15, function() {

					// Animate scroll down label after content animations are completed
					TweenMax.fromTo($scrollDown, 1, {
						opacity: 0
					}, {
						opacity: 1,
						ease: Power2.easeOut
					});

				});

			},

			fadeFromBottom = function($element) {

				// Fade element in from the bottom - opacity, y-axis

				if (!$element.length) return;

				$element.waypoint(function() {

					var $this = $(this.element);

					if ($this.hasClass('s-is_visible')) return;

					$this.addClass('s-is_visible');

					TweenMax.fromTo($this, 1, { y: '40px', opacity: '0' }, { y: '0px', opacity: '1', ease: Power2.easeOut });

				}, {
					offset: '85%'
				});

			},

			fadeIn = function($element) {

				// Fade element in - opacity

				if (!$element.length) return;

				$element.waypoint(function() {

					var $this = $(this.element);

					if ($this.hasClass('s-is_visible')) return;

					$this.addClass('s-is_visible');

					TweenMax.fromTo($this, 1, { opacity: '0' }, { opacity: '1', ease: Power2.easeOut });

				}, {
					offset: '85%'
				});


			},

			groupFadeFromBottom = function($parentElement, childClassName) {

				// Fade in all elements of a group from the bottom - opacity, y-axis

				if (!$parentElement.length) return;

				$parentElement.waypoint(function() {

					var $this = $(this.element),
						$thisGroupItem = $this.find(childClassName);

					if ($this.hasClass('s-is_visible')) return;

					$this.addClass('s-is_visible');

					TweenMax.staggerFromTo($thisGroupItem, 1, { y: '40px', opacity: '0' }, { y: '0px', opacity: '1', ease: Power2.easeOut }, 0.1);

				}, {
					offset: '85%'
				});

			},

			groupFadeIn = function($parentElement, childClassName) {

				// Fade in all elements of a group - opacity

				if (!$parentElement.length) return;

				$parentElement.waypoint(function() {

					var $this = $(this.element),
						$thisGroupItem = $this.find(childClassName);

					if ($this.hasClass('s-is_visible')) return;

					$this.addClass('s-is_visible');

					TweenMax.staggerFromTo($thisGroupItem, 1, { opacity: '0' }, { opacity: '1', ease: Power2.easeOut }, 0.1);

				}, {
					offset: '85%'
				});

			};

		return {
			init: function() {

				if ($body.hasClass('s-content_reveal_animations-is_enabled')) {

					var $header = $('.js-header'),
						$hero = $('.js-hero'),
						$fadeFromBottom = $('.js-fade_from_bottom'),
						$fadeIn = $('.js-fade_in'),
						$fadeFromBottomGroup = $('.js-fade_from_bottom_group'),
						$fadeInGroup = $('.js-fade_in_group');

					headerSlideIn($header);
					heroAnimateIn($hero);
					fadeFromBottom($fadeFromBottom);
					fadeIn($fadeIn);
					groupFadeFromBottom($fadeFromBottomGroup, '.js-fade_from_bottom_group_item');
					groupFadeIn($fadeInGroup, '.js-fade_in_group_item');

				}
			},
			groupFadeFromBottom: groupFadeFromBottom
		};
		
	};

})(jQuery);