/* ***** ----------------------------------------------- ***** **
** ***** Header JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.header = function() {

		var $body = $('.js-body'),
			$header = $('.js-header'),

			splitMenus = function() {

				// Split navigation items so they appear on each side of the centered logo

				var $headerLogo = $('.js-header-logo'),
					$nav = $('.js-header-nav'),
					$navItems = $nav.find('.o-site_nav-menu > .js-site_nav-menu_list_item'),
					headerLogoWidth = $headerLogo.width(),
					navMargin = (headerLogoWidth / 2) + 15,
					totalNavItems = $navItems.length,
					totalNavItemsLeft = Math.floor(totalNavItems / 2);

				// Clone the main navigation
				$nav.clone().prependTo($header);

				$('.js-header-nav').each(function(index) {
					var $this = $(this),
						$thisNavItems = $this.find('.o-site_nav-menu > .js-site_nav-menu_list_item');

					if (index === 0) {

						// For the left navigation, add the proper class and margin for styling
						$this.addClass('c-header-nav--left');
						$this.css('margin-right', navMargin + 'px');

						// Remove the second half of the navigation items
						$thisNavItems.each(function(index) {
							if (index >= totalNavItemsLeft) {
								$(this).remove();
							}
						});

					} else {

						// For the right navigation, add the proper class and margin for styling
						$this.addClass('c-header-nav--right');
						$this.css('margin-left', navMargin + 'px');

						// Remove the first half of the navigation items
						$thisNavItems.each(function(index) {
							if (index < totalNavItemsLeft) {
								$(this).remove();
							}
						});

					}

				});

				// Fade navigation in to avoid flash and shift in menu update
				setTimeout(function() {
					$header.removeClass('s-is_inactive');
				}, 100);

			},

			toggleTransparency = function() {

				// Add header background color when user is 100px down the page

				var scrollPosition = $(window).scrollTop();

				if (scrollPosition > 50) {
					$header.removeClass('s-is_transparent');
				} else {
					$header.addClass('s-is_transparent');
				}

			},

			toggleCompact = function() {

				// Add header background color when user is 100px down the page

				var scrollPosition = $(window).scrollTop();

				if (scrollPosition > 50) {
					$header.addClass('s-is_compact');
				} else {
					$header.removeClass('s-is_compact');
				}

			};

		return {
			init: function() {
				if ($header.hasClass('c-header--center_logo')) {
					splitMenus();
				}

				if ($body.hasClass('s-hero-is_active') &&
					!$body.hasClass('s-header_background-is_always_active')) {
					toggleTransparency();
				}

				if ($body.hasClass('s-header_size-is_compact')) {
					toggleCompact();
				}
			},
			toggleTransparency: toggleTransparency,
			toggleCompact: toggleCompact
		};
		
	};

})(jQuery);