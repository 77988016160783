/* ***** ----------------------------------------------- ***** **
** ***** Main JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	/* Vars */
	var $body = $('.js-body');

	/* Init */
	$(function(){

		Main.modules.init();

	});

	/* On Resize */
	$(window).resize(function() {

		Main.modules.menuOverlay().displayCheck();

	});

	/* On Scroll */
	$(window).scroll(function() {

		if ($body.hasClass('s-hero-is_active') &&
			!$body.hasClass('s-header_background-is_always_active')) {
			Main.modules.header().toggleTransparency();
		}

		if ($body.hasClass('s-header_size-is_compact')) {
			Main.modules.header().toggleCompact();
		}

		if ($body.hasClass('s-dropdown-is_open')) {
			Main.modules.sectionTitle().closeDropdown();
		}

	});

	/* Pulled into main.js to ensure it runs as soon as possible (Safari and Firefox glitch) */
	if ($body.hasClass('s-page_transitions-is_enabled') &&
		!$body.hasClass('s-customize_preview-is_active')) {

		if ($body.hasClass('s-loading_animation-is_enabled')) {
			$(window).bind('load', Main.modules.pageTransition().fadeIn);
		} else {
			Main.modules.pageTransition().fadeIn();
		}

	}

})(jQuery);
