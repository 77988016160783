/* ***** ----------------------------------------------- ***** **
** ***** Page Transition JS
** ***** ----------------------------------------------- ***** */

/* global Main, TweenMax */
(function($){
	'use strict';

	Main.modules.pageTransition = function() {

		var $body = $('.js-body'),
			$transitionOverlay = $('.js-transition_overlay'),
			transitionDuration = 0.5,

			fadeIn = function() {

				// 'Fade in' content by fading transition overlay out
				TweenMax.to($transitionOverlay, transitionDuration, {
					opacity: 0, 
					onComplete: function() { 
						$transitionOverlay.hide();
					}
				});

			},

			fadeOut = function(e) {
				e.preventDefault();

				var $this = $(this),
					url = $this.attr('href');

				if ($this.hasClass('js-menu_overlay-toggle_submenu')) return;

				$transitionOverlay.show();

				// 'Fade out' content by fading transition overlay in, and then loading new page
				TweenMax.to($transitionOverlay, transitionDuration, {
					opacity: 1,
					onComplete: function() {
						window.location.href = url;
					}
				});

			},

			browserFixes = function() {

				var $window = $(window);

				// Safari fix to clear bfcache so page transitions can work when user hits back/forward buttons
				$window.bind('pageshow', function(event) {
					if (event.originalEvent.persisted) {
						window.location.reload();
					}
				});

			};

		return {
			init: function() {
				if ($body.hasClass('s-page_transitions-is_enabled') &&
					!$body.hasClass('s-customize_preview-is_active')) {

					var $anchor = $('a[href*="/"][target!="_blank"][data-fancybox!="gallery"]');
					
					$anchor.on('click', fadeOut);
					browserFixes();

				}
			},
			fadeIn: fadeIn
		};
		
	};

})(jQuery);