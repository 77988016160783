/* ***** ----------------------------------------------- ***** **
** ***** Tour JS
** ***** ----------------------------------------------- ***** */

/* global Main */
(function($){
	'use strict';

	Main.modules.tour = function() {

		var $tour = $('.js-tour'),

			loadDates = function() {

				// Load tour dates from Bandsintown API based on artist name

				if (!$tour.length) return;

				var artistName = $tour.data('bandsintown'),
					appId = 'aa0930aa52ad808be784a65d37e9ce86',
					requestUrl = 'https://rest.bandsintown.com/artists/' + artistName + '/events?app_id=' + appId,
					loadingBarsMarkup = renderLoadingBars();

				// Display loading bars animation while data is being fetched
				$tour.append(loadingBarsMarkup);

				$.get(requestUrl, displayDates)
					.fail(displayErrorMessage)
					.always(removeLoadingBars);

			},

			displayDates = function(data) {

				// Display tour dates based on data fetched from the Bandsintown API

				if (data.length) {

					var datesToShow = $tour.data('dates_to_show');

					$.each(data, function(index, value) {

						// Return if we're currently over the number of dates to show liit
						if (datesToShow != -1 && index >= datesToShow) return;

						// Prep tour date data for markup rendering
						var dateTime = value.datetime,
							venue = value.venue.name,
							lineup = value.lineup,
							city = value.venue.city,
							region = value.venue.region,
							country = value.venue.country,
							location = city + ', ' + (region && !isInt(region) ? region : country),
							rsvpUrl = value.url,
							offers = value.offers,
							dateMarkup = renderTourDate(dateTime, venue, lineup, location, offers, rsvpUrl);

						// Append tour date markup to main tour container
						$tour.append(dateMarkup);

					});

					// Enable Waypoints on newly added tour dates
					Main.modules.contentAnimations().groupFadeFromBottom($tour, '.js-fade_from_bottom_group_item');

				} else {

					var errorMessageMarkup = '<p>Sorry, there are no upcoming tour dates.</p>';
				
					$tour.append(errorMessageMarkup);

				}

			},

			renderTourDate = function(dateTime, venue, lineup, location, offers, rsvpUrl) {

				// Render the complete tour date markup

				var tourDateMarkup = '',
					monthLabel = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
					dateObj = new Date(dateTime),
					month = dateObj.getMonth(),
					day = dateObj.getDate(),
					year = dateObj.getFullYear(),
					yearMonthDay = year + '-' + padWithZeros(month + 1, 2) + '-' + day,
					monthDay = monthLabel[month] + ' ' + padWithZeros(day, 2);

				tourDateMarkup += '<article class="o-tour_preview u-animation-opacity--0 js-fade_from_bottom_group_item">';
				tourDateMarkup += '<time class="o-tour_preview-date" datetime="' + yearMonthDay + '">' + monthDay + '</time>';
				tourDateMarkup += '<div class="o-tour_preview-venue_details">';
				tourDateMarkup += '<div class="o-tour_preview-venue">' + venue + '</div>';

				// If there are other artists on the bill (other than the current artist),
				// create a string that lists each artist
				if (lineup.length > 1) {

					var lineupString = '';

					$.each(lineup, function(index, value) {

						if (index == 0) return;

						if (index == 1) {
							lineupString += 'with ' + value;
						} else {
							lineupString += ', ' + value;
						}

					});

					tourDateMarkup += '<div class="o-tour_preview-details">' + lineupString + '</div>';

				}

				tourDateMarkup += '</div>';
				tourDateMarkup += '<div class="o-tour_preview-location">' + location + '</div>';
				tourDateMarkup += '<div class="o-tour_preview-buttons">';

				// For each available offer button, render with the proper label
				$.each(offers, function(index, value) {

					var type = value.type,
						url = value.url,
						buttonModifier = type == 'Sold Out' ? ' o-button--disabled' : '';

					tourDateMarkup += '<a href="' + url + '" class="o-tour_preview-button o-button o-button--small' + buttonModifier + '" target="_blank">';
					tourDateMarkup += '<span class="o-button-text o-button-text--default">' + type + '</span>';
					tourDateMarkup += '<span class="o-button-text o-button-text--hover">' + type + '</span>';
					tourDateMarkup += '</a>';

				});

				tourDateMarkup += '<a href="' + rsvpUrl + '" class="o-tour_preview-button o-button o-button--small" target="_blank">';
				tourDateMarkup += '<span class="o-button-text o-button-text--default">RSVP</span>';
				tourDateMarkup += '<span class="o-button-text o-button-text--hover">RSVP</span>';
				tourDateMarkup += '</a>';
				tourDateMarkup += '</div>';
				tourDateMarkup += '</article>';

				return tourDateMarkup;

			},

			displayErrorMessage = function() {

				// Display error message if something went wrong with the API call

				var errorMessageMarkup = '<p>There was an error with your request. Please try again.</p>';
				
				$tour.append(errorMessageMarkup);

			},

			renderLoadingBars = function() {

				// Render loading animation bars that are displayed while the tour data is being fetched

				var loadingBarsMarkup = '';

				loadingBarsMarkup += '<div class="o-loading_bars">';
				loadingBarsMarkup += '<div class="o-loading_bars-bar o-loading_bars-bar--1"></div>';
				loadingBarsMarkup += '<div class="o-loading_bars-bar o-loading_bars-bar--2"></div>';
				loadingBarsMarkup += '<div class="o-loading_bars-bar o-loading_bars-bar--3"></div>';
				loadingBarsMarkup += '<div class="o-loading_bars-bar o-loading_bars-bar--4"></div>';
				loadingBarsMarkup += '<div class="o-loading_bars-bar o-loading_bars-bar--5"></div>';
				loadingBarsMarkup += '</div>';

				return loadingBarsMarkup;

			},

			removeLoadingBars = function() {

				// Remove loading bars

				var $loadingBars = $('.o-loading_bars');

				$loadingBars.remove();

			},

			padWithZeros = function(number, size) {

				// Pad a number of a specified number of zeros

				var string = number + '';

				while (string.length < size) string = '0' + string;
				
				return string;

			},

			isInt = function(value) {

				// Check if value is a valid integer

				return !isNaN(value) && 
					parseInt(Number(value)) == value && 
					!isNaN(parseInt(value, 10));

			};

		return {
			init: function() {
				loadDates();
			}
		};
		
	};

})(jQuery);