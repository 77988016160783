/* ***** ----------------------------------------------- ***** **
** ***** Videos JS
** ***** ----------------------------------------------- ***** */

/* global Main, mejs */
(function($){
	'use strict';

	Main.modules.videos = function() {

		var $body = $('.js-body'),

			playVideo = function() {

				// Play video, which is stored in a hidden div, in a lightbox

				var lightboxContentClass = '#c-lightbox_content',
					$videoIframe = $(lightboxContentClass).find('.js-lightbox_content-video_iframe'),
					$nativeVideo = $(lightboxContentClass).find('.mejs-container');

				if ($videoIframe.length) {
					// Update embed URLs for YouTube and Vimeo to enable autoplay
					var videoIframeSrc = $videoIframe.attr('src'),
						videoIframeSrcWithAutoplay = videoIframeSrc + '&autoplay=1';
				} else if ($nativeVideo.length) {
					// For native video, get video embed ID for autoplay
					var nativeVideoId = $nativeVideo.attr('id');
				}

				$.fancybox.open([
					{
						src: lightboxContentClass,
						type: 'inline',
						opts: {
							beforeShow: function() {
								if ($videoIframe.length) {
									// Load updated URL with autoplay parameter upon opening lightbox
									$videoIframe.attr('src', videoIframeSrcWithAutoplay);
								} else if ($nativeVideo.length) {
									// Play video upon opening lightbox
									var player = mejs.players[nativeVideoId];
									player.play();
								}
							},
							afterClose: function() {
								if ($videoIframe.length) {
									$videoIframe.attr('src', videoIframeSrc);
								}
							},
						}
					},
				]);

			};

		return {
			init: function() {
				if ($body.hasClass('single-fh-video')) {
					var $playVideo = $('.js-play_video');

					$playVideo.on('click', playVideo);

					if ($body.hasClass('s-video_auto_open-is_enabled')) {
						$(window).bind('load', function() {
							$playVideo.click();
						});
					}
				}
			}
		};
		
	};

})(jQuery);