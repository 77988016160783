/* ***** ----------------------------------------------- ***** **
** ***** Menu Overlay JS
** ***** ----------------------------------------------- ***** */

/* global Main, TweenMax, Power2 */
(function($){
	'use strict';

	Main.modules.menuOverlay = function() {

		var $body = $('.js-body'),
			utilities = Main.modules.utilities(),
			isSmallWidth = utilities.isSmallWidth(),

			toggle = function() {

				// Toggle the display of the menu overlay

				var enableContentRevealAnimations = $body.hasClass('s-content_reveal_animations-is_enabled');

				$body.toggleClass('s-menu_overlay-is_open');

				// If content reveal animations are enabled, animate dropdown items
				if ($body.hasClass('s-menu_overlay-is_open') && enableContentRevealAnimations) {
					openAnimation();
				}

			},

			openAnimation = function() {

				// Animate icons in an offset manner and stagger animate the menu items

				var $menuOverlay = $('.js-menu_overlay'),
					$menuListItem = $menuOverlay.find('.o-site_nav-menu > .js-site_nav-menu_list_item'),
					$iconsEven = $menuOverlay.find('.js-icons-icon:even').parent(), // Select parent to avoid conflicts with transition property for hover
					$iconsOdd = $menuOverlay.find('.js-icons-icon:odd').parent(); // Select parent to avoid conflicts with transition property for hover

				TweenMax.fromTo($iconsEven, 0.5, { opacity: 0, y: '-30px' }, { opacity: 1, y: '0px', delay: 0.2, ease: Power2.easeOut });
				TweenMax.fromTo($iconsOdd, 0.5, { opacity: 0, y: '-30px' }, { opacity: 1, y: '0px', delay: 0.3, ease: Power2.easeOut });
				TweenMax.staggerFromTo($menuListItem, 0.5, { opacity: 0, y: '-20px' }, { opacity: 1, y: '0px', delay: 0.4, ease: Power2.easeOut, clearProps: 'all' }, 0.05);

			},

			displayCheck = function() {

				// If menu overlay is still open when the standard navigation is active, remove

				var $header = $('.js-header');

				if (!isSmallWidth && $header.hasClass('c-header--standard_navigation')) {
					$body.removeClass('s-menu_overlay-is_open');
				}

			},

			toggleSubMenu = function(e) {
				e.preventDefault();

				var $this = $(this),
					$menu = $('.js-menu_overlay-menu'),
					$menuOverlay = $('.js-menu_overlay'),
					duration = 0.7;

				$menuOverlay.toggleClass('s-submenu-is_open');

				if ($menuOverlay.hasClass('s-submenu-is_open')) {

					$menuOverlay.append('<div class="o-site_nav-submenu_slide_container js-menu_overlay-submenu_slide_container"></div>');

					$this.next('.js-site_nav-sub_menu')
						.clone()
						.appendTo('.js-menu_overlay-submenu_slide_container');

					$('.js-menu_overlay-submenu_slide_container')
						.find('.js-menu_overlay-toggle_submenu')
						.on('click', toggleSubMenu);

					TweenMax.fromTo($menu, duration, { x: '0%' }, { x: '100%', ease: Power2.easeInOut });
					TweenMax.fromTo($('.js-menu_overlay-submenu_slide_container'), duration, { x: '-100%' }, { x: '0%', delay: 0.2, ease: Power2.easeInOut });

				} else {

					TweenMax.fromTo($('.js-menu_overlay-submenu_slide_container'), duration, {
						x: '0%'
					}, {
						x: '-100%',
						ease: Power2.easeInOut,
						onComplete: function() {
							$('.js-menu_overlay-submenu_slide_container').remove();
						}
					});

					TweenMax.fromTo($menu, duration, { x: '100%' }, { x: '0%', delay: 0.2, ease: Power2.easeInOut });

				}

			};

		return {
			init: function() {
				var $toggle = $('.js-toggle_menu_overlay'),
					$toggleSubMenu = $('.js-menu_overlay-toggle_submenu');

				$toggle.on('click', toggle);
				$toggleSubMenu.on('click', toggleSubMenu);
			},
			displayCheck: displayCheck
		};
		
	};

})(jQuery);